import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Home.css";
import profilePhoto from "../../assets/profilePhoto.JPG";
// import photo from "../../assets/profile-circle.png";
import Archived from "../Archived/Archived";
import Fade from "react-reveal/Fade";
import Publications from "../Publications/publications";
import Work from "../Work/Work";

class About extends Component {
  state = {};
  render() {
    return (
      <div className="Home">
        {/* <section>
          <div className="screen">
            <div className="center-home">
              <Fade down delay={500}>
                <img alt="Raphael" src={photo} className="photo-raph" />
              </Fade>
              <Fade left delay={500}>
                <h1>
                  Hello, I'm <h1 className="h1color">Raphael David</h1>
                </h1>
              </Fade>
              <Fade right delay={500}>
                <div className="social-icon-center">
                  <Link
                    className="social-icons-link instagram"
                    to={{
                      pathname: "https://www.instagram.com/raphcondor/",
                    }}
                    target="_blank"
                    aria-label="Instagram"
                  >
                    <i className="fab fa-instagram"></i>
                  </Link>
                  <Link
                    className="social-icons-link github"
                    to={{ pathname: "https://github.com/RaphCondor" }}
                    target="_blank"
                    aria-label="Github"
                  >
                    <i className="fab fa-github"></i>
                  </Link>
                  <Link
                    className="social-icons-link linkedin"
                    to={{
                      pathname:
                        "https://www.linkedin.com/in/raphaeldavidcondor/",
                    }}
                    target="_blank"
                    aria-label="LinkedIn"
                  >
                    <i className="fab fa-linkedin"></i>
                  </Link>
                  <Link
                    className="social-icons-link mail"
                    to={{
                      pathname: "mailto:rdcondor30@gmail.com",
                    }}
                    target="_blank"
                    aria-label="Mail"
                  >
                    <i className="fas fa-envelope"></i>
                  </Link>
                </div>
              </Fade>
            </div>
          </div>
        </section> */}

        <section name="About">
          <div className="orders">
            <Fade left delay={300}>
              <div className="head-left">
                <div>
                  <h1 className="resp">Raphael David Condor</h1>
                  <br />
                  <h5 className="desc">
                    I am an undergraduate student in Electrical Engineering and
                    Computer Sciences & Bioengineering at{" "}
                    <a className="color" href="https://www.berkeley.edu/">
                      University of California - Berkeley
                    </a>
                    . I focus on developing software, hardware devices, and
                    human-centered design that help redefine the way we interact
                    with technology.
                  </h5>

                  <h5 className="desc">
                    Currently, I am a software developer student assistant at
                    Berkeley IT, where I contribute to the system design for{" "}
                    <a
                      className="color"
                      href="https://calcentral.berkeley.edu/"
                    >
                      CalCentral
                    </a>
                    . I am also an undergraduate researcher at{" "}
                    <a
                      className="color"
                      href="https://www.hybrid-ecologies.org/"
                    >
                      Hybrid Ecologies Lab
                    </a>
                    , specializing in Human-Computer Interaction. Previously, I
                    was a Medical Devices Hardware Intern at the{" "}
                    <a
                      className="color"
                      href="https://healthtech.berkeley.edu/"
                    >
                      UC Berkeley - Health Tech CoLab
                    </a>{" "}
                    where I worked on low-cost data acquisition systems for
                    electrochemical sensors.
                  </h5>

                  <h5 className="desc">
                    Outside of school, I am a plant dad, at-home coffee barista,
                    and tech enthusiast. In my free time, I like to 🏃‍♂️, 🚴‍♂️, and
                    🏊‍♂️.
                  </h5>
                  <h5 className="desc">
                    If you'd like to connect, feel free to reach out via the
                    platforms below or email me at
                    raphael.condor[at]berkeley[dot]edu.
                  </h5>

                  <div className="social-icon">
                    <Link
                      className="social-icons-link instagram"
                      to={{
                        pathname: "https://www.instagram.com/raphcondor/",
                      }}
                      target="_blank"
                      aria-label="Instagram"
                    >
                      <i className="fab fa-instagram"></i>
                    </Link>

                    <Link
                      className="social-icons-link linkedin"
                      to={{
                        pathname:
                          "https://www.linkedin.com/in/raphaeldavidcondor/",
                      }}
                      target="_blank"
                      aria-label="LinkedIn"
                    >
                      <i className="fab fa-linkedin"></i>
                    </Link>
                    <Link
                      className="social-icons-link github"
                      to={{ pathname: "https://github.com/RaphCondor" }}
                      target="_blank"
                      aria-label="Github"
                    >
                      <i className="fab fa-github"></i>
                    </Link>
                    <Link
                      className="social-icons-link mail"
                      to={{
                        pathname: "mailto:rdcondor30@gmail.com",
                      }}
                      target="_blank"
                      aria-label="Mail"
                    >
                      <i className="fas fa-envelope"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </Fade>
            <Fade right delay={300}>
              <div className="right">
                <center>
                  <img
                    alt="Raphael"
                    src={profilePhoto}
                    className="photoProfile"
                  />
                </center>
              </div>
            </Fade>
          </div>
        </section>

        <section name="Publications">
          <div>
            <h1 className="resp">selected publications</h1>
            <div className="publications"></div>
            <Publications />
          </div>
        </section>

        <section name="Work">
          <div>
            <h1 className="resp">work</h1>
            <Work />
          </div>
        </section>

        <section>
          <div>
            <h1 className="resp">archived projects</h1>
            <Archived />
          </div>
        </section>
      </div>
    );
  }
}

export default About;
