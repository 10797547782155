import './App.css';
import NavBar from './components/Navbar/navbar';
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./components/Pages/Home";
import Footer from "./components/Footer/Footer";


function App() {
  return (
    <React.Fragment >
      <Router>
        <NavBar />
        <Switch>
          <Route path="/" exact component={Home} />
        </Switch>
        <Footer />
      </Router >
    </React.Fragment>
  );
}

export default App;