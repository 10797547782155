import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import medicam from "../../assets/projects/medicam.png";
import covidaware from "../../assets/projects/covidaware.png";
import geosafe from "../../assets/projects/geosafe.png";
import fertisoil from "../../assets/projects/fertisoil.png";
import buoywatch from "../../assets/projects/buoywatch.png";
import vitalaid from "../../assets/projects/vitalaidphoto.png";
import cropatrol from "../../assets/projects/cropatrol.png";
import blackbug from "../../assets/projects/blackbug.png";
// import { Link } from "react-router-dom";
import "./Archived.css";

const list = [
  geosafe,
  buoywatch,
  medicam,
  covidaware,
  fertisoil,
  cropatrol,
  blackbug,
  vitalaid,
];
const desc = [
  "An earthquake mapping app as a geographical information system.",
  "A system that detects and reports illegal fishing.",
  "An app that diagnoses diseases from medical images.",
  "A mobile app showing COVID statistics at the peak of the pandemic.",
  "An on-site N-P-K content analysis for soil assessment.",
  "A proposed system that monitors and detects pests and plant diseases.",
  "A script that detects blackbug pests using contour detection",
  "A vital signs monitoring device only utilizing the user's fingertip.",
];
const title = [
  "Geo Safe • 2020",
  "Buoy Watch • 2020",
  "Medicam • 2020",
  "Covid Aware • 2020",
  "NPK Soil Analysis • 2019",
  "Cropatrol • 2018",
  "Pest Detection • 2018",
  "Vital Aid • 2018",
];

const links = [
  "https://github.com/RaphCondor/geosafe",
  "https://github.com/RaphCondor/buoy-watch-app",
  "https://github.com/RaphCondor/medicam",
  "https://github.com/RaphCondor/covid_aware",
  "https://github.com/RaphCondor/NPK-Soil-Assessment",
  "https://github.com/RaphCondor/crop-patrol",
  "https://github.com/RaphCondor/crop-patrol/tree/main/Pest%20Counter",
  "https://github.com/RaphCondor/vital-aid",
];

const badges = [
  <div>
    <span class="badge bg-success">Flutter</span>{" "}
    <span class="badge bg-success">Google Maps SDK</span>
  </div>,
  <div>
    <span class="badge bg-success">Flutter</span>{" "}
    <span class="badge bg-success">Google Maps SDK</span>{" "}
    <span class="badge bg-success">OpenCV</span>{" "}
    <span class="badge bg-success">Raspberry Pi</span>
  </div>,
  <div>
    <span class="badge bg-success">Flutter</span>{" "}
    <span class="badge bg-success">Tensorflow</span>{" "}
    <span class="badge bg-success">OpenCV</span>
  </div>,
  <span class="badge bg-success">Flutter</span>,

  <span class="badge bg-success">Arduino</span>,
  <div>
    <span class="badge bg-success">Arduino</span>{" "}
    <span class="badge bg-success">Raspberry Pi</span>
  </div>,
  <div>
    <span class="badge bg-success">Python</span>{" "}
    <span class="badge bg-success">OpenCV</span>
  </div>,
  <span class="badge bg-success">Arduino</span>,
];

function Archived() {
  return (
    <Container className="archive">
      <Row xs={1} md={3} className="g-4">
        {Array.from({ length: 8 }).map((_, idx) => (
          <Col key={idx}>
            <a href={links[idx]} aria-label="Github">
              <Card className="content">
                <Card.Img variant="top" src={list[idx]} />
                <Card.ImgOverlay className="texts text-wrap">
                  <Card.Title className="text-center">
                    <b className="titlefont">{title[idx]}</b>
                  </Card.Title>
                  <Card.Body className="text-wrap">
                    <Card.Text>
                      {desc[idx]} <i className="fab fa-github githubs"></i>
                    </Card.Text>
                  </Card.Body>{" "}
                  <Card.Footer className="text-wrap">{badges[idx]}</Card.Footer>
                </Card.ImgOverlay>
              </Card>
            </a>
          </Col>
        ))}
      </Row>
      <br></br>
    </Container>
  );
}

export default Archived;
