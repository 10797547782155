import Container from "react-bootstrap/Container";
import logo from "../../assets/logo_final.png";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import resume from "../../assets/pdfs/resume.pdf";
// import { Link } from "react-router-dom";
import { Link } from "react-scroll";
import React, { useState } from "react";
import "./navbar.css";

function NavBar() {
  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar
      expanded={expanded}
      collapseOnSelect
      expand="lg"
      sticky="top"
      className="bg-body-tertiary navbars"
    >
      <Container>
        <Navbar.Brand>
          {" "}
          <Link
            activeClass="active"
            to="Home"
            spy={true}
            smooth={true}
            duration={500}
            offset={expanded ? -260 : -110}
          >
            <img
              src={logo}
              width="75"
              height="75"
              className="d-inline-block align-top"
              alt="Raphael David Condor Logo"
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => setExpanded(!expanded)}
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link>
              <Link
                onClick={() => setExpanded(false)}
                activeClass="active"
                to="About"
                spy={false}
                smooth={true}
                offset={expanded ? -260 : -110}
                duration={500}
              >
                About
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link
                onClick={() => setExpanded(false)}
                activeClass="active"
                to="Publications"
                offset={expanded ? -260 : -110}
                spy={false}
                smooth={true}
                duration={500}
              >
                Publications
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link
                onClick={() => setExpanded(false)}
                activeClass="active"
                to="Work"
                offset={expanded ? -260 : -110}
                spy={false}
                smooth={true}
                duration={500}
              >
                Work
              </Link>
            </Nav.Link>
            <Nav.Link href={resume}>Resume</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
