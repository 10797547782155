import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import CNF from "../../assets/work/CNF.jpg";
import potentiostat from "../../assets/work/potentiostat.jpg";
import printer from "../../assets/work/printer.jpg";
import chromatography from "../../assets/work/chromatography.jpg";
import "./Work.css";

const list = [printer, chromatography, potentiostat, CNF];
const title = [
  "Syringe-based Bioprinter",
  "Chromatography",
  "Electrochemical Potentiostat",
  "Cellulose Nanofibers",
];

function Work() {
  return (
    <Row xs={2} md={4} className="g-4">
      {Array.from({ length: 4 }).map((_, idx) => (
        <Col key={idx}>
          <Card className="content">
            <Card.Img variant="top" src={list[idx]} />
            <Card.ImgOverlay className="overlay-texts">
              <Card.Title className="text-center ">
                <b className="font text-wrap">{title[idx]}</b>
              </Card.Title>
              {/* <Card.Body className="text-wrap">
                <Card.Text>{desc[idx]}</Card.Text>
              </Card.Body>{" "} */}
            </Card.ImgOverlay>
          </Card>
        </Col>
      ))}
    </Row>
  );
}

export default Work;
