import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PDF179 from "../../assets/pdfs/pdf179.pdf";
import PDF180 from "../../assets/pdfs/pdf180.pdf";
import PDF142 from "../../assets/pdfs/pdf142.pdf";
import "../Pages/Home.css";

function ContainerExample() {
  return (
    <Container>
      <Row xs={1} md="auto">
        <Col>
          <h6>
            <span class="badge bg-success">IPOPHL</span>
          </h6>
        </Col>
        <Col>
          <div>
            <p className="pat">
              <b>
                IPOPHL 2-2020-050179: Organic Cellulose Nanofibers Wound
                Dressing
              </b>
              <br></br>
              <u>Raphael David B. Condor</u>, Avi Conerose Therese S. Malana,
              Kyra Jillian L. Namor, Benito A. Baje<br></br>
              <i>
                Official Gazette fo the Intellectual Property Office of the
                Philippines, Vol 23; No 116, 2020
              </i>{" "}
              <br></br>
              <a href="https://patents.google.com/patent/PH22020050179Y1/en">
                <span class="badge bg-secondary">Abstract</span>
              </a>
              {"  "}
              <a href={PDF179}>
                <span class="badge bg-secondary">PDF</span>
              </a>
            </p>{" "}
          </div>
        </Col>
      </Row>

      <Row xs={1} md="auto">
        <Col>
          <h6>
            <span class="badge bg-success">IPOPHL</span>
          </h6>
        </Col>
        <Col>
          <div>
            <p className="pat">
              <b>
                IPOPHL 2-2020-050180: Method for Producing Organic Cellulose
                Nanofibers
              </b>
              <br></br>
              <u>Raphael David B. Condor</u>, Avi Conerose Therese S. Malana,
              Kyra Jillian L. Namor, Benito A. Baje<br></br>
              <i>
                Official Gazette fo the Intellectual Property Office of the
                Philippines, Vol 23; No 116, 2020
              </i>{" "}
              <br></br>
              <a href="https://patents.google.com/patent/PH22020050180Y1/en">
                <span class="badge bg-secondary">Abstract</span>
              </a>
              {"  "}
              <a href={PDF180}>
                <span class="badge bg-secondary">PDF</span>
              </a>
            </p>{" "}
          </div>
        </Col>
      </Row>

      <Row xs={1} md="auto">
        <Col>
          <h6>
            <span class="badge bg-success">IPOPHL</span>
          </h6>
        </Col>
        <Col>
          <div>
            <p className="pat">
              <b>
                IPOPHL 2-2020-050142: System for Determining N-P-K Concentration
                and Ratio in Soil
              </b>
              <br></br>
              <u>Raphael David B. Condor</u>, Kyra Jillian L. Namor, Benito A.
              Baje<br></br>
              <i>
                Official Gazette fo the Intellectual Property Office of the
                Philippines, Vol 23; No 111, 2020
              </i>{" "}
              <br></br>
              <a href="https://patents.google.com/patent/PH22020050142U1/en">
                <span class="badge bg-secondary">Abstract</span>
              </a>
              {"  "}
              <a href={PDF142}>
                <span class="badge bg-secondary">PDF</span>
              </a>
            </p>{" "}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ContainerExample;
