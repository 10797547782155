import React, { Component } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

class Footer extends Component {
  state = {};
  render() {
    return (
      <div className="footer-container">
        <div className="left">
          <p className="white">© Raphael David Condor 2023</p>
        </div>
        <div className="social-media">
          <div className="social-icons">
            <Link
              className="social-icon-link instagram"
              to={{ pathname: "https://www.instagram.com/raphcondor/" }}
              target="_blank"
              aria-label="Instagram"
            >
              <i className="fab fa-instagram"></i>
            </Link>

            {/* <Link
              className="social-icon-link twitter"
              to={{ pathname: "https://twitter.com/raphaelcondor" }}
              target="_blank"
              aria-label="Twitter"
            >
              <i className="fab fa-twitter"></i>
            </Link> */}
            <Link
              className="social-icon-link linkedin"
              to={{
                pathname: "https://www.linkedin.com/in/raphaeldavidcondor/",
              }}
              target="_blank"
              aria-label="LinkedIn"
            >
              <i className="fab fa-linkedin"></i>
            </Link>
            <Link
              className="social-icon-link github"
              to={{ pathname: "https://www.github.com/RaphCondor" }}
              target="_blank"
              aria-label="Facebook"
            >
              <i className="fab fa-github"></i>
            </Link>
            <Link
              className="social-icon-link mail"
              to={{
                pathname: "mailto:rdcondor30@gmail.com",
              }}
              target="_blank"
              aria-label="Mail"
            >
              <i className="fas fa-envelope"></i>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
